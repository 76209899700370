import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeroContainer } from "../Containers";
import AirtablePlus from "airtable-plus";
import Lodash from "lodash";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      news: []
    };
  }

  async componentDidMount() {
    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Posts",
      maxRecords: 2
    });

    const news = await airtable.read({ view: "Website" });
    this.setState({ news });
  }

  render() {
    const { news } = this.state;

    return (
      <div>
        <HeroContainer history={this.props.history} />
        <section>
          <div className="container">
            <div className="row section__divider middle-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <a className="box" href="/services#port-agency">
                      <div className="box__content">
                        <div className="box__title">Port Agency</div>
                        <div className="box__icon">
                          <img
                            src="../assets/i/solution-right-arrow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <a className="box" href="/services#husbandry-services">
                      <div className="box__content">
                        <div className="box__title">Husbandry Services</div>
                        <div className="box__icon">
                          <img
                            src="../assets/i/solution-right-arrow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <a className="box" href="/services#transit-services">
                      <div className="box__content">
                        <div className="box__title">Transit Services</div>
                        <div className="box__icon">
                          <img
                            src="../assets/i/solution-right-arrow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <a className="box" href="/services#bunker-call-agency">
                      <div className="box__content">
                        <div className="box__title">Bunker Call Agency</div>
                        <div className="box__icon">
                          <img
                            src="../assets/i/solution-right-arrow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <a className="box" href="/services#protective-agency">
                      <div className="box__content">
                        <div className="box__title">Protective Agency</div>
                        <div className="box__icon">
                          <img
                            src="../assets/i/solution-right-arrow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <a className="box" href="/services#shipyard-services">
                      <div className="box__content">
                        <div className="box__title">Shipyard Services</div>
                        <div className="box__icon">
                          <img
                            src="../assets/i/solution-right-arrow.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1">
                <div className="solution">
                  <div className="solution__title">
                    Solutions that fulfills your needs
                  </div>
                  <div className="solution__description">
                    <p>
                      We guarantee the quickest, the most accurate and economic
                      services to our valued customers in all requests with our
                      experienced staff. We provide shipping agency services at
                      all Turkish Ports and Straits.
                    </p>
                  </div>
                  <a href="/services" className="solution__footer">
                    <span>See All</span>
                    <img src="../assets/i/right-arrow.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row section__divider middle-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row section__divider">
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-lg-offset-2">
                    <div className="solution__title">Eksay in numbers</div>
                    <p>
                      We are specialised to handle all types of tankers and our
                      clients enjoy the comfort of working with a full agency
                      service from a well known reputable agency in liquid bulk
                      business with over 30 years of experience.
                    </p>
                    <p>
                      We understand the importance of dry bulk cargo in various
                      industries and always ensure to involved parties that we
                      meet their needs as quick and efficient as possible. Dry
                      bulk cargo is mostly described by iron ore and coal, but
                      we demonstrate our expertise in serving our valued
                      customers vessels loaded with fertilizers, steel products,
                      food grains, timbers and all other bulk cargoes.
                    </p>
                    <div className="row section__divider">
                      <div className="col-xs-4 section__divider">
                        <div className="numbers">
                          <div className="numbers__title">69%</div>
                          <div className="numbers__description">
                            Port Call Oparations
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-4 section__divider">
                        <div className="numbers">
                          <div className="numbers__title">20%</div>
                          <div className="numbers__description">
                            Transit Services
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-4 section__divider">
                        <div className="numbers">
                          <div className="numbers__title">11%</div>
                          <div className="numbers__description">
                            Dry Docking & Repairs
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title">28%</div>
                          <div className="numbers__description">Chemicals</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title">22%</div>
                          <div className="numbers__description">
                            Petrochemicals
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title">8%</div>
                          <div className="numbers__description">Oil & Gas</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title">15%</div>
                          <div className="numbers__description">Coal</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title">12%</div>
                          <div className="numbers__description">
                            Steel Products
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title">15%</div>
                          <div className="numbers__description">Fertilizer</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row start-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-lg-offset-1">
                <div className="solution">
                  <div className="solution__title">Latest news</div>
                  <div className="solution__description">
                    <p>
                      Check out our latest news. Make sure to come frequently
                      for world maritime news and our company updates.
                    </p>
                  </div>
                  <a href="/news" className="solution__footer">
                    <span>See All</span>
                    <img src="../assets/i/right-arrow.svg" alt="" />
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-lg-offset-1 first-xs last-sm section__divider">
                <div className="row">
                  {news.map((post, ___) =>
                    ___ < 2 ? (
                      <a
                        key={___}
                        href={
                          post.fields.Outside
                            ? post.fields.URL
                            : "/news/" + post.id
                        }
                        class="col-xs-12 col-sm-6 col-md-6 col-lg-6"
                      >
                        <div class="announce">
                          <div class="announce__header">news</div>
                          <div class="announce__title">{post.fields.Title}</div>
                          <div class="announce__description">
                            <p>
                              {post.fields.Description.length > 200
                                ? post.fields.Description.substring(0, 200) +
                                  "..."
                                : post.fields.Description}
                            </p>
                          </div>
                        </div>
                      </a>
                    ) : (
                      undefined
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
