import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import NavbarContainer from "./NavbarContainer";
import AirtablePlus from "airtable-plus";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: ""
    };
  }

  __quote = async () => {
    let { quote } = this.props.redux;

    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Home Contacts"
    });

    const create = await airtable.create(
      {
        Name: this.state.name,
        Email: this.state.email,
        Phone: this.state.phone
      },
      { tableName: "Website Content - Home Contacts" }
    );

    quote.name = this.state.name;
    quote.email = this.state.email;
    quote.phone = this.state.phone;

    this.props.core("quote", quote);
    this.props.history.push("/request-a-quote", quote);
  };

  render() {
    const { name, email, phone } = this.state;

    return (
      <header className="header header--hero">
        <div className="container">
          <NavbarContainer />
          <div className="header__content">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <h1>Accurate, Fast and Cost Effective</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                <p className="--no-bottom-margin">
                  We support our worldwide principals with the best service for
                  quickest and economical vessel turnarounds
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                <div className="quote">
                  <div className="quote__header">
                    <h3>Need quote in more detail</h3>
                    <p>info@eksay.com — +90 216 302 01 29</p>
                  </div>
                  <div className="quote__footer">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="icon-container">
                          <div className="icon-container__icon">
                            <img src="/assets/i/icon-user.svg" />
                          </div>
                          <input
                            className="input input--icon"
                            placeholder="Name Surname"
                            value={name}
                            onChange={event =>
                              this.setState({ name: event.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="icon-container">
                          <div className="icon-container__icon">
                            <img src="/assets/i/icon-email.svg" />
                          </div>
                          <input
                            className="input input--icon"
                            placeholder="E-Mail"
                            value={email}
                            onChange={event =>
                              this.setState({ email: event.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <div className="icon-container">
                          <div className="icon-container__icon">
                            <img src="/assets/i/icon-phone.svg" />
                          </div>
                          <input
                            className="input input--icon"
                            placeholder="Phone"
                            value={phone}
                            onChange={event =>
                              this.setState({ phone: event.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                        <a id="home-get-started" href="#" className="button" onClick={this.__quote}>
                          Get Started
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="logos">
                  <img src="../assets/i/logos1.svg" alt="" />
                  <img src="../assets/i/logos2.svg" alt="" />
                  <img src="../assets/i/logos3.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
