import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

class Screen extends React.PureComponent {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4">
              <a className="logo logo--light --default-bottom-margin" href="/"></a>
              <p>Eksay Shipping and Trading Inc.</p>
              <p>Atatürk Mah. Ekincioğlu Sok. No:22 34987 Ataşehir, İstanbul</p>
              <p>
                Mersis No: 0330002021800010
                <br />
                Tax ID: 3300020218
                <br />
                Trade Registration Number: 226220
              </p>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 col-lg-offset-3">
              <div className="footer__title">Company</div>
              <a href="/services" className="footer__item">
                Services
              </a>
              <a href="/request-a-quote" className="footer__item">
                Quotation
              </a>
              <a href="/team" className="footer__item">
                Team
              </a>
              <a href="/about-us" className="footer__item">
                About Us
              </a>
              <a href="/contact" className="footer__item --default-bottom-margin">
                Contact
              </a>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2">
              <div className="footer__title">Services</div>
              <a href="/services#port-agency" className="footer__item">
                Port Agency
              </a>
              <a href="/services#lay-ups" className="footer__item">
                Lay-ups
              </a>
              <a href="/services#husbandary-services" className="footer__item">
                Husbandary Services
              </a>
              <a href="/services#transit-services" className="footer__item">
                Transit Services
              </a>
              <a href="/services#bunker-call-agency" className="footer__item">
                Bunker Call Agency
              </a>
              <a href="/services#protective-agency" className="footer__item">
                Protective Agency
              </a>
              <a href="/services#shipyard-services" className="footer__item">
                Shipyard Services
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
