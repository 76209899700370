import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, Header2Container } from "../Containers";

class Screen extends React.PureComponent {
  render() {
    return (
      <div>
        <Header2Container title="Supply Services Coming Soon" description="" />
        <section>
          <div className="container">
            <kbd>
              {".container > .row > .col-xs-12 .col-sm-12 .col-md-6 .col-lg-6"}
            </kbd>
            <div className="row section__divider middle-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  className="image"
                  src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
          <div className="container">
            <kbd>
              {".container > .row > .col-xs-12 .col-sm-12 .col-md-6 .col-lg-6"}
            </kbd>
            <div className="row section__divider middle-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6" />

              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <img
                  className="image"
                  src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row section__divider middle-xs center-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <kbd>.image</kbd>
                <img
                  className="image"
                  src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row section__divider middle-xs center-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <kbd>.image .image--border</kbd>
                <img
                  className="image image--border"
                  src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row section__divider middle-xs center-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <kbd>.image .image--ghost</kbd>
                <img
                  className="image image--ghost"
                  src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row section__divider middle-xs center-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <kbd>.image .image--hover</kbd>
                <img
                  className="image image--hover"
                  src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row section__divider middle-xs center-xs">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <kbd>.image .image--border .image--ghost .image--hover</kbd>
                <img
                  className="image image--border image--ghost image--hover"
                  src="https://images.pexels.com/photos/906982/pexels-photo-906982.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                />
              </div>
            </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
