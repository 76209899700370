import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeaderContainer } from "../Containers";

class Screen extends React.PureComponent {
  render() {
    return (
      <div>
        <HeaderContainer
          title="Services"
          description="The quickest, most accurate and cost effective agency services for 38 years. Our professional approach before, during and after port calls has made us an agency of choice"
        />
        <section>
          <div class="container">
            <div class="row section__divider">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="sidebar section__divider">
                  <a class="item" href="#port-agency">
                    Port Agency
                  </a>
                  <a class="item" href="#lay-ups">
                    Lay-ups
                  </a>
                  <a class="item" href="#husbandry-services">
                    Husbandry Services
                  </a>
                  <a class="item" href="#transit-services">
                    Transit Services
                  </a>
                  <a class="item" href="#bunker-call-agency">
                    Bunker Call Agency
                  </a>
                  <a class="item" href="#protective-agency">
                    Protective Agency
                  </a>
                  <a class="item" href="#shipyard-services">
                    Shipyard Services
                  </a>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <p>
                  We guarantee the quickest, the most accurate and economic
                  services to our valued customers in all issues with our
                  experienced staff. We provide shipping agency services at all
                  Turkish Ports and Straits, including protecting, husbandry,
                  laid up agency services; repairing & drydocking; attendance
                  during loading & discharging operations; garbage & waste
                  disposal arrangements; delivery & clearance services; crew
                  change services; motorboat & car service; and arrangements for
                  medical services.
                </p>
                <p>
                  Services such as ship’s supply arrangements (bunker,
                  lubricants, provisions, bonded stores, fresh water, etc.),
                  underwater inspections and hull, deck & engine surveys are
                  coordinated with the well-known and reputable suppliers in
                  their fields:
                </p>
                <div class="section__divider" id="port-agency">
                  <h2>Port Agency</h2>
                  <p>
                    We organize, oversee and coordinate all aspects of the port
                    call, from booking berth allocations and services ahead of
                    the vessel’s arrival to finalizing the accounts and other
                    paperwork after vessel’s sail.
                  </p>
                </div>
                <div class="section__divider" id="lay-ups">
                  <h2>Lay-ups</h2>
                  <p>
                    We take care of the lay-up of your vessel and deliver an
                    agency service package including the lay-up, and later the
                    re-employment to service. As the only total lay-up solution
                    service provider in Turkey, We are a one-stop shop for all
                    your lay-ups requirements.
                  </p>
                </div>
                <div class="section__divider" id="husbandry-services">
                  <h2>Husbandry Services</h2>
                  <p>
                    We deliver cost-effective and on-time husbandry services;
                  </p>
                  <br />
                  <p>
                    • Ship’s supply arrangements
                    (bunkers,lubricants,provisions,freshwater,bonded stores
                    etc.)
                  </p>
                  <p>• Repairs and drydocking services</p>
                  <p>• Hull, deck and engine survey support</p>
                  <p>• Garbage and waste oil disposal arrangements</p>
                  <p>• Diving support</p>
                  <p>• Arranging medical services</p>
                  <p>• Crew change services</p>
                  <p>• Motorboat and car services</p>
                  <p>
                    • Delivery and clearance services like cash to master,
                    spare, mail and store
                  </p>
                </div>
                <div class="section__divider" id="transit-services">
                  <h2>Transit Services</h2>
                  <p>
                    Eksay Shipping involve all operational, official, financial
                    and legal attendances on behalf of the ship, manager, owner
                    and charterers during Turkish Straits passages.
                  </p>
                </div>
                <div class="section__divider" id="bunker-call-agency">
                  <h2>Bunker Call Agency</h2>
                  <p>
                    We make your calls smoother and simpler by coordinating
                    bunker supplies, surveys and all necessary formalities prior
                    arrival.
                  </p>
                </div>
                <div class="section__divider" id="protective-agency">
                  <h2>Protective Agency</h2>
                  <p>
                    Eksay deliver protecting agency services both for shipowners
                    and charterers to supervise counterparty’s agents throughout
                    the port calls and/or transit passages in order to avoid any
                    conflict of interest which may arise with many stakeholders
                    involved in a call..
                  </p>
                </div>
                <div class="section__divider" id="shipyard-services">
                  <h2>Shipyard Services</h2>
                  <p>
                    With our professional team, we provide agency services to
                    your ships 24/7 at the shipyards in Tuzla and Yalova. During
                    the repair process, we carry out transit spare parts
                    delivery and personnel replacement meticulously and on time.
                    With more than 38 years of experience and experience, you
                    can trust Eksay for your shipyard repair agency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <iframe
          src="https://www.google.com/maps/d/embed?mid=1v5YHlercXDyVnuNkhuHPulHgcps"
          style={{ width: "100%", height: 400 }}
          border="false"
        ></iframe>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
