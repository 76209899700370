import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeaderContainer } from "../Containers";
import Select from "react-select";
import AirtablePlus from "airtable-plus";

const SELECT = {
  container: (provided, state) => ({
    ...provided,
    marginBottom: 24
  }),
  control: (provided, state) => ({
    ...provided,
    borderRadius: 8,
    border: "1px solid #DFE4E5",
    height: 48,
    paddingLeft: 8,
    fontSize: 14
  })
};

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      vessel: false,
      length_overall: "",
      gross_tonnage: "",
      net_tonnage: "",
      deadweight_tonnage: "",
      operation: false,
      cargoes: "",
      metric_tonnes: "",
      ports: false,
      additional_requests: "",
      error: false,
      sent: false
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      this.setState({
        name: this.props.location.state.name,
        email: this.props.location.state.email,
        phone: this.props.location.state.phone
      });
    }
  }

  __send = async () => {
    this.setState({ error: false });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.phone === "" ||
      this.state.vessel === false ||
      this.state.length_overall === "" ||
      this.state.gross_tonnage === "" ||
      this.state.net_tonnage === "" ||
      this.state.deadweight_tonnage === "" ||
      this.state.operation === false ||
      this.state.cargoes === "" ||
      this.state.metric_tonnes === "" ||
      this.state.ports === false ||
      this.state.additional_requests === ""
    ) {
      this.setState({ error: true });
      return;
    }

    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Request a Quote"
    });

    let ports = "";
    let vessel = "";

    if (this.state.ports) {
      this.state.ports.map(port => (ports = ports + ", " + port.value));
    }

    if (this.state.vessel) {
      vessel = this.state.vessel.value;
    }

    const create = await airtable.create(
      {
        Name: this.state.name,
        Email: this.state.email,
        Phone: this.state.phone,
        "Vessel Type": vessel,
        "Length Overall": this.state.length_overall,
        "Gross Tonnage": this.state.gross_tonnage,
        "Net Tonnage": this.state.net_tonnage,
        "Deadweight Tonnage": this.state.deadweight_tonnage,
        Operation: this.state.operation,
        Cargoes: this.state.cargoes,
        "Cargo Quantity / Metric Tonnes": this.state.metric_tonnes,
        Ports: ports,
        "Additional Requests": this.state.additional_requests
      },
      { tableName: "Website Content - Request a Quote" }
    );

    this.setState({
      name: "",
      email: "",
      phone: "",
      vessel: false,
      length_overall: "",
      gross_tonnage: "",
      net_tonnage: "",
      deadweight_tonnage: "",
      operation: false,
      cargoes: "",
      metric_tonnes: "",
      ports: false,
      additional_requests: "",
      error: false,
      sent: true
    });
  };

  render() {
    const {
      name,
      email,
      phone,
      vessel,
      length_overall,
      gross_tonnage,
      net_tonnage,
      deadweight_tonnage,
      operation,
      cargoes,
      metric_tonnes,
      ports,
      additional_requests,
      error,
      sent
    } = this.state;

    return (
      <div>
        <HeaderContainer
          title="Need quote in more detail?"
          description="We support our worldwide principals with the best service for quickest and economical vessel turnarounds"
        />
        <section>
          <div className="container">
            <h2>1. Contact information</h2>
            <div className="row section__divider">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <div className="icon-container">
                  <div className="icon-container__icon">
                    <img src="/assets/i/icon-user.svg" />
                  </div>
                  <input
                    className="input input--icon"
                    placeholder="Name Surname"
                    name="name"
                    value={name}
                    onChange={event =>
                      this.setState({ name: event.target.value })
                    }
                  />
                </div>
                <div className="icon-container">
                  <div className="icon-container__icon">
                    <img src="/assets/i/icon-email.svg" />
                  </div>
                  <input
                    className="input input--icon"
                    placeholder="Name E-Mail"
                    type="email"
                    name="email"
                    value={email}
                    onChange={event =>
                      this.setState({ email: event.target.value })
                    }
                  />
                </div>
                <div className="icon-container">
                  <div className="icon-container__icon">
                    <img src="/assets/i/icon-phone.svg" />
                  </div>
                  <input
                    className="input input--icon"
                    placeholder="Name Phone"
                    name="phone"
                    value={phone}
                    onChange={event =>
                      this.setState({ phone: event.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <h2>2. Vessel information</h2>
            <div className="row bottom-xs section__divider">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <Select
                  defaultValue={{
                    label: "Tanker",
                    value: "Tanker"
                  }}
                  styles={SELECT}
                  value={vessel}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  onChange={vessel => this.setState({ vessel })}
                  placeholder="Vessel Type"
                  options={[
                    { label: "Tanker", value: "Tanker" },
                    { label: "LNG/LPG Tanker", value: "LNG/LPG Tanker" },
                    { label: "Chemical Tanker", value: "Chemical Tanker" },
                    { label: "Bulk Carrier", value: "Bulk Carrier" },
                    {
                      label: "General Cargo Ship",
                      value: "General Cargo Ship"
                    },
                    { label: "Container Ship", value: "Container Ship" }
                  ]}
                />
                <input
                  className="input"
                  placeholder="Length Overall"
                  type="number"
                  value={length_overall}
                  onChange={event =>
                    this.setState({ length_overall: event.target.value })
                  }
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <input
                  className="input"
                  placeholder="Gross Tonnage"
                  type="number"
                  value={gross_tonnage}
                  onChange={event =>
                    this.setState({ gross_tonnage: event.target.value })
                  }
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <input
                  className="input"
                  placeholder="Net Tonnage"
                  type="number"
                  value={net_tonnage}
                  onChange={event =>
                    this.setState({ net_tonnage: event.target.value })
                  }
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <input
                  className="input"
                  placeholder="Deadweight Tonnage"
                  type="number"
                  value={deadweight_tonnage}
                  onChange={event =>
                    this.setState({ deadweight_tonnage: event.target.value })
                  }
                />
              </div>
            </div>
            <h2>3. Operational information</h2>
            <h3>Requested operation</h3>
            <div className="row">
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <a
                  className={
                    operation === "Transit" ? "pill pill--active" : "pill"
                  }
                  onClick={() => this.setState({ operation: "Transit" })}
                >
                  <div className="pill__radio">
                    <div className="pill__radio__icon"></div>
                  </div>
                  <div className="pill__title">Transit</div>
                  <div className="pill__description">
                    Bosphorus & Dardanelles passage
                  </div>
                </a>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <a
                  className={
                    operation === "Loading / Unloading"
                      ? "pill pill--active"
                      : "pill"
                  }
                  onClick={() =>
                    this.setState({ operation: "Loading / Unloading" })
                  }
                >
                  <div className="pill__radio">
                    <div className="pill__radio__icon"></div>
                  </div>
                  <div className="pill__title">Loading / Unloading</div>
                  <div className="pill__description">Port calls</div>
                </a>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <a
                  className={
                    operation === "Drydock & Repair"
                      ? "pill pill--active"
                      : "pill"
                  }
                  onClick={() =>
                    this.setState({ operation: "Drydock & Repair" })
                  }
                >
                  <div className="pill__radio">
                    <div className="pill__radio__icon"></div>
                  </div>
                  <div className="pill__title">Drydock & Repair</div>
                  <div className="pill__description">
                    Repair, paint and other services
                  </div>
                </a>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                <a
                  className={
                    operation === "Husbandry Services"
                      ? "pill pill--active"
                      : "pill"
                  }
                  onClick={() =>
                    this.setState({ operation: "Husbandry Services" })
                  }
                >
                  <div className="pill__radio">
                    <div className="pill__radio__icon"></div>
                  </div>
                  <div className="pill__title">Husbandry Services</div>
                  <div className="pill__description">
                    Repair, paint and other services
                  </div>
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <input
                  className="input"
                  placeholder="Cargoes"
                  type="number"
                  value={cargoes}
                  onChange={event =>
                    this.setState({ cargoes: event.target.value })
                  }
                />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3">
                <input
                  className="input"
                  placeholder="Cargo Quantity / Metric Tonnes"
                  type="number"
                  value={metric_tonnes}
                  onChange={event =>
                    this.setState({ metric_tonnes: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-9">
                <Select
                  styles={SELECT}
                  value={ports}
                  isDisabled={false}
                  isLoading={false}
                  isClearable={false}
                  isRtl={false}
                  isSearchable={true}
                  onChange={ports => this.setState({ ports })}
                  placeholder="Ports"
                  isMulti={true}
                  options={[
                    {
                      label: "CKZ-0006 - Port Facility of Çanakale Akcansa",
                      value: "CKZ-0006 - Port Facility of Çanakale Akcansa"
                    },
                    {
                      label: "TRIST-0012 - Port of Akcansa (Ambarli)",
                      value: "TRIST-0012 - Port of Akcansa (Ambarli)"
                    },
                    {
                      label: "TRALA-0001 - Pier of Alanya",
                      value: "TRALA-0001 - Pier of Alanya"
                    },
                    {
                      label: "TRMRM-0001 - Port of Marmaris",
                      value: "TRMRM-0001 - Port of Marmaris"
                    },
                    {
                      label: "TRIZT-0010 - Port Facility of Diler",
                      value: "TRIZT-0010 - Port Facility of Diler"
                    },
                    {
                      label: "TRIST-0020 - Quay of Gisas ",
                      value: "TRIST-0020 - Quay of Gisas "
                    },
                    {
                      label:
                        "TRIZT-0023 - Port of Solventas Teknik Depolama A.Ş.",
                      value:
                        "TRIZT-0023 - Port of Solventas Teknik Depolama A.Ş."
                    },
                    {
                      label: "TRISK-0007 - Port Facility of Yazici",
                      value: "TRISK-0007 - Port Facility of Yazici"
                    },
                    {
                      label: "TRIZT-0015 - Port Facility of Kroman Çelik A.Ş.",
                      value: "TRIZT-0015 - Port Facility of Kroman Çelik A.Ş."
                    },
                    {
                      label: "TRKUS-0002 - Port Facility of Kusadasi",
                      value: "TRKUS-0002 - Port Facility of Kusadasi"
                    },
                    {
                      label: "TRIZT-0011 - Berth of Ford Otosan",
                      value: "TRIZT-0011 - Berth of Ford Otosan"
                    },
                    {
                      label: "TRYLA-0001 - Port Facility of Aksa",
                      value: "TRYLA-0001 - Port Facility of Aksa"
                    },
                    {
                      label: "TRIZT-0016 - Port of Aslan Cimento",
                      value: "TRIZT-0016 - Port of Aslan Cimento"
                    },
                    {
                      label: "TRCES-0002 - Port of Ulusoy Çeşme",
                      value: "TRCES-0002 - Port of Ulusoy Çeşme"
                    },
                    {
                      label: "TRIZT-0024 - Refinery of Tupras İzmit",
                      value: "TRIZT-0024 - Refinery of Tupras İzmit"
                    },
                    {
                      label: "TRSSX-0003 - Terminal of Aygaz Samsun",
                      value: "TRSSX-0003 - Terminal of Aygaz Samsun"
                    },
                    {
                      label: "TRMER-0012 - Terminal of Opet Mersin",
                      value: "TRMER-0012 - Terminal of Opet Mersin"
                    },
                    {
                      label: "TRISK-0006 - Filling Terminal of Sasa İskenderun",
                      value: "TRISK-0006 - Filling Terminal of Sasa İskenderun"
                    },
                    {
                      label: "TRDYL-0005 - Petgaz Terminal of LPG Filling",
                      value: "TRDYL-0005 - Petgaz Terminal of LPG Filling"
                    },
                    {
                      label: "TRDYL-0003 - Terminal of Aygaz A.Ş. (Dortyol)",
                      value: "TRDYL-0003 - Terminal of Aygaz A.Ş. (Dortyol)"
                    },
                    {
                      label: "TRIST-0008 - Terminal of Aygaz A.Ş. (Ambarli)",
                      value: "TRIST-0008 - Terminal of Aygaz A.Ş. (Ambarli)"
                    },
                    {
                      label: "TRMER-0005 - Filling Facility of Alpet Mersin",
                      value: "TRMER-0005 - Filling Facility of Alpet Mersin"
                    },
                    {
                      label: "TRAMB-0001 - Container Terminal of Marport",
                      value: "TRAMB-0001 - Container Terminal of Marport"
                    },
                    {
                      label: "TRIST-0014 - Port of Kumport (Ambarli)",
                      value: "TRIST-0014 - Port of Kumport (Ambarli)"
                    },
                    {
                      label: "TRIST-0015 - Pier of Mardaş (Ambarli)",
                      value: "TRIST-0015 - Pier of Mardaş (Ambarli)"
                    },
                    {
                      label: "TRAYT-0005 - Port of Akdeniz",
                      value: "TRAYT-0005 - Port of Akdeniz"
                    },
                    {
                      label: "TRZEY-0001 - Port of Zeyport",
                      value: "TRZEY-0001 - Port of Zeyport"
                    },
                    {
                      label:
                        "TRTEK-0003 - Port Facility of Martas (Marmara Ereğlisi)",
                      value:
                        "TRTEK-0003 - Port Facility of Martas (Marmara Ereğlisi)"
                    },
                    {
                      label: "TRALI-0029 - Port of Egeçelik",
                      value: "TRALI-0029 - Port of Egeçelik"
                    },
                    {
                      label: "TRIZT-0022 - Terminal of Shell Derince",
                      value: "TRIZT-0022 - Terminal of Shell Derince"
                    },
                    {
                      label: "TRMER-0006 - Terminal of Ataş (Mersin)",
                      value: "TRMER-0006 - Terminal of Ataş (Mersin)"
                    },
                    {
                      label:
                        "TRGEM-0005 - Pipe Line and Buoy System of MKS Marmara",
                      value:
                        "TRGEM-0005 - Pipe Line and Buoy System of MKS Marmara"
                    },
                    {
                      label: "TRAYT-0045 - Terminal of Çekisan (Antalya)",
                      value: "TRAYT-0045 - Terminal of Çekisan (Antalya)"
                    },
                    {
                      label: "TRAYT-0015 - Oil Terminal of Shell Turcas",
                      value: "TRAYT-0015 - Oil Terminal of Shell Turcas"
                    },
                    {
                      label: "TRGEM-0001 - Port of Borusan",
                      value: "TRGEM-0001 - Port of Borusan"
                    },
                    {
                      label: "TRGEM-0006 - Port of Gemport (Gemlik)",
                      value: "TRGEM-0006 - Port of Gemport (Gemlik)"
                    },
                    {
                      label:
                        "TRIZT-0003 - Terminal of Cayirova Cam Sanayi (Gebze)",
                      value:
                        "TRIZT-0003 - Terminal of Cayirova Cam Sanayi (Gebze)"
                    },
                    {
                      label: "TRBXN-0001 - Yacht Port of Bodrum",
                      value: "TRBXN-0001 - Yacht Port of Bodrum"
                    },
                    {
                      label: "TRIZM-0005 - Port Facility of Batıliman",
                      value: "TRIZM-0005 - Port Facility of Batıliman"
                    },
                    {
                      label: "TRDIK-0001 - Port of Dikili",
                      value: "TRDIK-0001 - Port of Dikili"
                    },
                    {
                      label: "TRALI-0033 - Port of İDÇ Nemrut",
                      value: "TRALI-0033 - Port of İDÇ Nemrut"
                    },
                    {
                      label: "TRIZT-0008 - Port of Altintel",
                      value: "TRIZT-0008 - Port of Altintel"
                    },
                    {
                      label: "TRIZT-0012 - Port of Gubretas Yarimca",
                      value: "TRIZT-0012 - Port of Gubretas Yarimca"
                    },
                    {
                      label: "TRIZT-0001 - Terminal of Aktaş",
                      value: "TRIZT-0001 - Terminal of Aktaş"
                    },
                    {
                      label: "TRCKZ-0002 - Port of Icdas-1",
                      value: "TRCKZ-0002 - Port of Icdas-1"
                    },
                    {
                      label:
                        "TRIZT-0018 - Port Facility of Petline Körfez (Filling)",
                      value:
                        "TRIZT-0018 - Port Facility of Petline Körfez (Filling)"
                    },
                    {
                      label: "TRUNY-0001 - Port of Unye",
                      value: "TRUNY-0001 - Port of Unye"
                    },
                    {
                      label:
                        "TRDIL-0001 - Port Facility of Colakoglu Metalurji",
                      value: "TRDIL-0001 - Port Facility of Colakoglu Metalurji"
                    },
                    {
                      label: "TRGEM-0003 - Terminal of BP Gemlik",
                      value: "TRGEM-0003 - Terminal of BP Gemlik"
                    },
                    {
                      label: "TRDRC-0004 - Port of Koruma Klor Alkali",
                      value: "TRDRC-0004 - Port of Koruma Klor Alkali"
                    },
                    {
                      label:
                        "TRIZT-0028 - Terminal of Güzel Enerji Akaryakıt A.Ş.",
                      value:
                        "TRIZT-0028 - Terminal of Güzel Enerji Akaryakıt A.Ş."
                    },
                    {
                      label:
                        "TRTEK-0002 - Trakya LPG Storage Terminal of Butangaz",
                      value:
                        "TRTEK-0002 - Trakya LPG Storage Terminal of Butangaz"
                    },
                    {
                      label: "TRIZT-0027 - Evyap Port",
                      value: "TRIZT-0027 - Evyap Port"
                    },
                    {
                      label: "TRIZT-0019 - Port of Poliport (Gebze)",
                      value: "TRIZT-0019 - Port of Poliport (Gebze)"
                    },
                    {
                      label: "TRALI-0004 - Terminal of Tüpraş Aliağa",
                      value: "TRALI-0004 - Terminal of Tüpraş Aliağa"
                    },
                    {
                      label: "TRALI-0001 - Port Facility of Ege Gubre",
                      value: "TRALI-0001 - Port Facility of Ege Gubre"
                    },
                    {
                      label: "TRALI-0002 - Port of Habas",
                      value: "TRALI-0002 - Port of Habas"
                    },
                    {
                      label: "TRISK-0002 - Port of Gübretaş",
                      value: "TRISK-0002 - Port of Gübretaş"
                    },
                    {
                      label: "TRCEY-0004 - Port of Botas Ceyhan",
                      value: "TRCEY-0004 - Port of Botas Ceyhan"
                    },
                    {
                      label: "TRDYL-0004 - Port of Botas Dortyol",
                      value: "TRDYL-0004 - Port of Botas Dortyol"
                    },
                    {
                      label: "TRTEK-0001 - Terminal of Botas LNG",
                      value: "TRTEK-0001 - Terminal of Botas LNG"
                    },
                    {
                      label: "TRHER-0002 - Port Facility of Nuh Cimento",
                      value: "TRHER-0002 - Port Facility of Nuh Cimento"
                    },
                    {
                      label: "TRBXN-0003 - Yacht Port of D-Marine Turgutreis",
                      value: "TRBXN-0003 - Yacht Port of D-Marine Turgutreis"
                    },
                    {
                      label: "TRIZT-0020 - Port of Rota (Kocaeli)",
                      value: "TRIZT-0020 - Port of Rota (Kocaeli)"
                    },
                    {
                      label: "TRHOP-0001 - Port of Hopa (Artvin)",
                      value: "TRHOP-0001 - Port of Hopa (Artvin)"
                    },
                    {
                      label: "TRIZT-0017 - Port of Limas",
                      value: "TRIZT-0017 - Port of Limas"
                    },
                    {
                      label: "TRBDM-0007 - Port of Çelebi Bandırma",
                      value: "TRBDM-0007 - Port of Çelebi Bandırma"
                    },
                    {
                      label: "TRHAY-0001 - Port of Haydarpaşa (TCDD)",
                      value: "TRHAY-0001 - Port of Haydarpaşa (TCDD)"
                    },
                    {
                      label: "TRIZM-0008 - Port of Alsancak",
                      value: "TRIZM-0008 - Port of Alsancak"
                    },
                    {
                      label: "TRSSX-0008 - Port Of Samsun",
                      value: "TRSSX-0008 - Port Of Samsun"
                    },
                    {
                      label: "TRDRC-0001 - Port of Safi (Derince)",
                      value: "TRDRC-0001 - Port of Safi (Derince)"
                    },
                    {
                      label: "TRISK-0009 - Port of Limakport (İskenderun)",
                      value: "TRISK-0009 - Port of Limakport (İskenderun)"
                    },
                    {
                      label: "TRMER-0004 - Port of M.I.P.",
                      value: "TRMER-0004 - Port of M.I.P."
                    },
                    {
                      label: "TRTEK-0005 - Port of Ceyport",
                      value: "TRTEK-0005 - Port of Ceyport"
                    },
                    {
                      label:
                        "TRCKZ-0004 - Port Facility of Municipality of Karabiga",
                      value:
                        "TRCKZ-0004 - Port Facility of Municipality of Karabiga"
                    },
                    {
                      label: "TRSSX-0005 - Terminal of Sadaş (Samsun)",
                      value: "TRSSX-0005 - Terminal of Sadaş (Samsun)"
                    },
                    {
                      label: "TRDYL-0001 - Terminal of Ipragaz (Dörtyol)",
                      value: "TRDYL-0001 - Terminal of Ipragaz (Dörtyol)"
                    },
                    {
                      label: "TRIZT-0004 - Platform of Habaş of Tütünçiftlik",
                      value: "TRIZT-0004 - Platform of Habaş of Tütünçiftlik"
                    },
                    {
                      label: "TRALI-0031 - Port of Petkim",
                      value: "TRALI-0031 - Port of Petkim"
                    },
                    {
                      label: "TRAYT-0003 - Terminal of POAS Antalya",
                      value: "TRAYT-0003 - Terminal of POAS Antalya"
                    },
                    {
                      label: "TRISK-0004 - Terminal of POAS İskenderun",
                      value: "TRISK-0004 - Terminal of POAS İskenderun"
                    },
                    {
                      label: "TRSSX-0002 - Terminal of POAS Samsun",
                      value: "TRSSX-0002 - Terminal of POAS Samsun"
                    },
                    {
                      label: "TRALI-0036 - Terminal of Socar Turkey",
                      value: "TRALI-0036 - Terminal of Socar Turkey"
                    },
                    {
                      label: "TRIST-0006 - Terminal of POAS Haramidere",
                      value: "TRIST-0006 - Terminal of POAS Haramidere"
                    },
                    {
                      label: "TRTZX-0001 - Terminal of POAS Trabzon",
                      value: "TRTZX-0001 - Terminal of POAS Trabzon"
                    },
                    {
                      label: "TRDRC-0002 - Terminal of POAS Derince",
                      value: "TRDRC-0002 - Terminal of POAS Derince"
                    },
                    {
                      label: "TRISK-0005 - Port of Isdemir",
                      value: "TRISK-0005 - Port of Isdemir"
                    },
                    {
                      label: "TRALI-0003 - Oil Terminal of Aliağa Güzel Enerji",
                      value: "TRALI-0003 - Oil Terminal of Aliağa Güzel Enerji"
                    },
                    {
                      label: "TRTZX-0002 - Port Facility of Trabzon",
                      value: "TRTZX-0002 - Port Facility of Trabzon"
                    },
                    {
                      label: "TRDYL-0006 - Terminal of Rubis Oil",
                      value: "TRDYL-0006 - Terminal of Rubis Oil"
                    },
                    {
                      label: "TRMER-0008 - Berth of Mersin Free Zone",
                      value: "TRMER-0008 - Berth of Mersin Free Zone"
                    },
                    {
                      label: "TRBDM-0005 - Port of Bagfas of Bandirma",
                      value: "TRBDM-0005 - Port of Bagfas of Bandirma"
                    },
                    {
                      label: "TRISK-0003 - Orhan Ekinci Pier",
                      value: "TRISK-0003 - Orhan Ekinci Pier"
                    },
                    {
                      label: "TRFET-0001 - Port Facility of Fethiye",
                      value: "TRFET-0001 - Port Facility of Fethiye"
                    },
                    {
                      label: "TRDIL-0004 - Port Facility of Efesanport",
                      value: "TRDIL-0004 - Port Facility of Efesanport"
                    },
                    {
                      label: "TRCEY-0005 - Terminal of Toros Tarim Ceyhan",
                      value: "TRCEY-0005 - Terminal of Toros Tarim Ceyhan"
                    },
                    {
                      label: "TRCEY-0002 - Sugozu Discharging Pier",
                      value: "TRCEY-0002 - Sugozu Discharging Pier"
                    },
                    {
                      label: "TRIZT-0013 - Port of Igsas",
                      value: "TRIZT-0013 - Port of Igsas"
                    },
                    {
                      label: "TRIZT-0025 - Terminal of TP Petrol & Izgin",
                      value: "TRIZT-0025 - Terminal of TP Petrol & Izgin"
                    },
                    {
                      label: "TRBTN-0001 - Port of Bartın",
                      value: "TRBTN-0001 - Port of Bartın"
                    },
                    {
                      label: "TRFAS-0001 - Pier of Fatsa (Ordu)",
                      value: "TRFAS-0001 - Pier of Fatsa (Ordu)"
                    },
                    {
                      label: "TRTAS-0001 - Port of TDİ Taşucu",
                      value: "TRTAS-0001 - Port of TDİ Taşucu"
                    },
                    {
                      label: "TRGIR-0002 - Port of Giresun",
                      value: "TRGIR-0002 - Port of Giresun"
                    },
                    {
                      label: "TRTAS-0002 - Port of Municipality of Tasucu",
                      value: "TRTAS-0002 - Port of Municipality of Tasucu"
                    },
                    {
                      label: "TRRIZ-0001 - Port of Riport Rize",
                      value: "TRRIZ-0001 - Port of Riport Rize"
                    },
                    {
                      label: "TRIST-0011 - Terminal of Galataport (İstanbul)",
                      value: "TRIST-0011 - Terminal of Galataport (İstanbul)"
                    },
                    {
                      label: "TRAYV-0001 - Pier of Ayvalık Ship",
                      value: "TRAYV-0001 - Pier of Ayvalık Ship"
                    },
                    {
                      label: "TRZON-0002 - Port of TTK Zonguldak",
                      value: "TRZON-0002 - Port of TTK Zonguldak"
                    },
                    {
                      label: "TRDYL-0002 - Terminal of Milangaz Dörtyol",
                      value: "TRDYL-0002 - Terminal of Milangaz Dörtyol"
                    },
                    {
                      label: "TRIZT-0031 - Filling Terminal of Milangaz",
                      value: "TRIZT-0031 - Filling Terminal of Milangaz"
                    },
                    {
                      label: "TRERE-0001 - Port of Erdemir",
                      value: "TRERE-0001 - Port of Erdemir"
                    },
                    {
                      label: "TRMRA-0001 - Port of Saraylar (Marmara Island)",
                      value: "TRMRA-0001 - Port of Saraylar (Marmara Island)"
                    },
                    {
                      label: "TRINE-0001 - Port of Inebolu",
                      value: "TRINE-0001 - Port of Inebolu"
                    },
                    {
                      label:
                        "TRZON-0003 - Port of Municipality (Bozhane) of Karadeniz Ereğli",
                      value:
                        "TRZON-0003 - Port of Municipality (Bozhane) of Karadeniz Ereğli"
                    },
                    {
                      label: "TRMER-0003 - Terminal of Energy Mersin",
                      value: "TRMER-0003 - Terminal of Energy Mersin"
                    },
                    {
                      label: "TRMRM-0003 - Port Facility Of Datça",
                      value: "TRMRM-0003 - Port Facility Of Datça"
                    },
                    {
                      label: "TRSSX-0004 - Port of Toros Tarim Samsun",
                      value: "TRSSX-0004 - Port of Toros Tarim Samsun"
                    },
                    {
                      label: "TRTEK-0004 - Pier of TMO Tekirdag",
                      value: "TRTEK-0004 - Pier of TMO Tekirdag"
                    },
                    {
                      label: "TRIST-0021 - Port of DFDS Pendik",
                      value: "TRIST-0021 - Port of DFDS Pendik"
                    },
                    {
                      label: "TRMER-0001 - Terminal of Akpet of Mersin",
                      value: "TRMER-0001 - Terminal of Akpet of Mersin"
                    },
                    {
                      label: "TRCKZ-0003 - Port of Kepez",
                      value: "TRCKZ-0003 - Port of Kepez"
                    },
                    {
                      label: "TRMER-0002 - Terminal of Nergis Mersin",
                      value: "TRMER-0002 - Terminal of Nergis Mersin"
                    },
                    {
                      label:
                        "TRCEY-0003 - Oil Terminal of BTC Haydar Aliyev (Ceyhan)",
                      value:
                        "TRCEY-0003 - Oil Terminal of BTC Haydar Aliyev (Ceyhan)"
                    },
                    {
                      label: "TRSSX-0006 - Port of Yesilyurt",
                      value: "TRSSX-0006 - Port of Yesilyurt"
                    },
                    {
                      label: "TRHAT-0002 - Oil Terminal of Yeniyurt",
                      value: "TRHAT-0002 - Oil Terminal of Yeniyurt"
                    },
                    {
                      label: "TRGIR-0003 - Terminal of Karadeniz LPG",
                      value: "TRGIR-0003 - Terminal of Karadeniz LPG"
                    },
                    {
                      label:
                        "TRTEK-0006 - Opet Oil Terminal (Marmara Ereğlisi)",
                      value: "TRTEK-0006 - Opet Oil Terminal (Marmara Ereğlisi)"
                    },
                    {
                      label: "TRSSX-0012 - Alpet Buoy Facility",
                      value: "TRSSX-0012 - Alpet Buoy Facility"
                    },
                    {
                      label: "TRGUL-0002 - Port of Yeni Güllük",
                      value: "TRGUL-0002 - Port of Yeni Güllük"
                    },
                    {
                      label: "TRFOC-0001 - Berths of Foça Municipality",
                      value: "TRFOC-0001 - Berths of Foça Municipality"
                    },
                    {
                      label: "TRALI-0005 - Terminal Of Ege Gaz LNG",
                      value: "TRALI-0005 - Terminal Of Ege Gaz LNG"
                    },
                    {
                      label: "TRALI-0006 - Terminal of Alpet Aliağa",
                      value: "TRALI-0006 - Terminal of Alpet Aliağa"
                    },
                    {
                      label: "TRGIR-0004 - Terminal of Opet Giresun-Espiye",
                      value: "TRGIR-0004 - Terminal of Opet Giresun-Espiye"
                    },
                    {
                      label: "TRGEM-0012 - Port of Roda Gemlik",
                      value: "TRGEM-0012 - Port of Roda Gemlik"
                    },
                    {
                      label: "TRIZT-0037 - Port Facility of Yilport (Kocaeli)",
                      value: "TRIZT-0037 - Port Facility of Yilport (Kocaeli)"
                    },
                    {
                      label:
                        "TRTEK-0007 - Port facility of Terminal of Marmara (Marmara Ereğlisi)",
                      value:
                        "TRTEK-0007 - Port facility of Terminal of Marmara (Marmara Ereğlisi)"
                    },
                    {
                      label: "TRBXN-0006 - Bodrum Cruise Port (Muğla)",
                      value: "TRBXN-0006 - Bodrum Cruise Port (Muğla)"
                    },
                    {
                      label: "TRIZT-0047 - Port  of Autoport (Kocaeli)",
                      value: "TRIZT-0047 - Port  of Autoport (Kocaeli)"
                    },
                    {
                      label: "TRBXN-0008 - Yalikavak Marina",
                      value: "TRBXN-0008 - Yalikavak Marina"
                    },
                    {
                      label: "TRIZT-0051 - Opay Filling Facility",
                      value: "TRIZT-0051 - Opay Filling Facility"
                    },
                    {
                      label: "TRALI-0011 - Berth of Nemport",
                      value: "TRALI-0011 - Berth of Nemport"
                    },
                    {
                      label: "TRIST-0074 - Pier of Sarayburnu",
                      value: "TRIST-0074 - Pier of Sarayburnu"
                    },
                    {
                      label: "TRMAR-0001 - LPG Filling Facility of Argaz",
                      value: "TRMAR-0001 - LPG Filling Facility of Argaz"
                    },
                    {
                      label: "TRSSX-0032 - Terminal of Akpet (Samsun)",
                      value: "TRSSX-0032 - Terminal of Akpet (Samsun)"
                    },
                    {
                      label: "TRMER-0052 - Terminal of Euroil Kazanlı Mersin",
                      value: "TRMER-0052 - Terminal of Euroil Kazanlı Mersin"
                    },
                    {
                      label: "TRMER-0053 - Terminal Mersin of Savka",
                      value: "TRMER-0053 - Terminal Mersin of Savka"
                    },
                    {
                      label: "TRISK-0029 - Berth of MMK Metalurji",
                      value: "TRISK-0029 - Berth of MMK Metalurji"
                    },
                    {
                      label: "TRADN-0004 - D-Marine Didim Marina",
                      value: "TRADN-0004 - D-Marine Didim Marina"
                    },
                    {
                      label: "TRZON-0009 - Port of Eren",
                      value: "TRZON-0009 - Port of Eren"
                    },
                    {
                      label: "TRISK-0033 - Assan Port (İskenderun)",
                      value: "TRISK-0033 - Assan Port (İskenderun)"
                    },
                    {
                      label: "TRAYT-0022 - Terminal of Akdeniz Oil Filling",
                      value: "TRAYT-0022 - Terminal of Akdeniz Oil Filling"
                    },
                    {
                      label: "TRIST-0084 - Terminal of THY Opet (Yeşilköy)",
                      value: "TRIST-0084 - Terminal of THY Opet (Yeşilköy)"
                    },
                    {
                      label: "TRCES-0007 - Yatch Port of Sigacik",
                      value: "TRCES-0007 - Yatch Port of Sigacik"
                    },
                    {
                      label: "TRCKZ-0022 - Port Facility of Icdas-2",
                      value: "TRCKZ-0022 - Port Facility of Icdas-2"
                    },
                    {
                      label:
                        "TRKAS-0001 - Port Facilitiy of Municipality of Kas",
                      value:
                        "TRKAS-0001 - Port Facilitiy of Municipality of Kas"
                    },
                    {
                      label: "TRTZX-0013 - Terminal of Aytemiz (Trabzon)",
                      value: "TRTZX-0013 - Terminal of Aytemiz (Trabzon)"
                    },
                    {
                      label: "TRBZI-0001 - Pier of Tuna Mining",
                      value: "TRBZI-0001 - Pier of Tuna Mining"
                    },
                    {
                      label: "TRALI-0024 - Nemrut Platform Facility",
                      value: "TRALI-0024 - Nemrut Platform Facility"
                    },
                    {
                      label: "TRYUM-0001 - Port Facility of Sanko",
                      value: "TRYUM-0001 - Port Facility of Sanko"
                    },
                    {
                      label: "TRTAS-0010 - Port of Yeşilovacık",
                      value: "TRTAS-0010 - Port of Yeşilovacık"
                    },
                    {
                      label: "TRTEK-0039 - Asyaport",
                      value: "TRTEK-0039 - Asyaport"
                    },
                    {
                      label: "TRMRA-0004 - Berth of ERS",
                      value: "TRMRA-0004 - Berth of ERS"
                    },
                    {
                      label:
                        "TRYAR-0002 - Container Terminal of DP World Yarımca",
                      value:
                        "TRYAR-0002 - Container Terminal of DP World Yarımca"
                    },
                    {
                      label: "TRKRB-0001 - Pier of Cenal Karabiga",
                      value: "TRKRB-0001 - Pier of Cenal Karabiga"
                    },
                    {
                      label:
                        "TRAMB-0002 - Terminal of Ambarli Filling and Storage",
                      value:
                        "TRAMB-0002 - Terminal of Ambarli Filling and Storage"
                    },
                    {
                      label: "TRALI-0034 - Terminal of Container of SOCAR",
                      value: "TRALI-0034 - Terminal of Container of SOCAR"
                    },
                    {
                      label: "TRALA-0006 - Filling Facility of Aytemiz Oil",
                      value: "TRALA-0006 - Filling Facility of Aytemiz Oil"
                    },
                    {
                      label: "TRALI-0035 - Port of Etki",
                      value: "TRALI-0035 - Port of Etki"
                    },
                    {
                      label: "TRKRS-0001 - Port of Karasu",
                      value: "TRKRS-0001 - Port of Karasu"
                    },
                    {
                      label: "TRSIC-0003 - Port of Sinop",
                      value: "TRSIC-0003 - Port of Sinop"
                    },
                    {
                      label: "TRAYT-0004 - Port of Asbaş Free Zone",
                      value: "TRAYT-0004 - Port of Asbaş Free Zone"
                    },
                    {
                      label: "TRYLA-0002 - Terminal of Yalova Ro-Ro",
                      value: "TRYLA-0002 - Terminal of Yalova Ro-Ro"
                    },
                    {
                      label: "TRDIL-0005 - Belde Port",
                      value: "TRDIL-0005 - Belde Port"
                    },
                    {
                      label: "TRCEY-0006 - Port Facility of Sönmez Çimento",
                      value: "TRCEY-0006 - Port Facility of Sönmez Çimento"
                    },
                    {
                      label:
                        "TRBZC-0002 - Port Facility of Pier of Gestas Bozcaada",
                      value:
                        "TRBZC-0002 - Port Facility of Pier of Gestas Bozcaada"
                    },
                    {
                      label: "TRHAT-0003 - Port Facility of Atakaş",
                      value: "TRHAT-0003 - Port Facility of Atakaş"
                    },
                    {
                      label: "TRCKZ-0023 - Gestaş Küçükkuyu",
                      value: "TRCKZ-0023 - Gestaş Küçükkuyu"
                    },
                    {
                      label: "TRALI-0037 - Port Facility of Star Oil Refinery",
                      value: "TRALI-0037 - Port Facility of Star Oil Refinery"
                    },
                    {
                      label: "TRISK-0034 - Port of Tosyalı (İskenderun)",
                      value: "TRISK-0034 - Port of Tosyalı (İskenderun)"
                    },
                    {
                      label: "TRIZT-0059 - Pier of Tavşancıl Asphalt",
                      value: "TRIZT-0059 - Pier of Tavşancıl Asphalt"
                    },
                    {
                      label:
                        "TRIST-0085 - Oil Terminal of İstanbul New Airport (İGA)",
                      value:
                        "TRIST-0085 - Oil Terminal of İstanbul New Airport (İGA)"
                    },
                    {
                      label: "TRIST-0086 - Port of Kumcular",
                      value: "TRIST-0086 - Port of Kumcular"
                    },
                    {
                      label: "TRMER-0054 - Terminal of East Cargo of Akkuyu",
                      value: "TRMER-0054 - Terminal of East Cargo of Akkuyu"
                    }
                  ]}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <textarea
                  className="yourmessage"
                  placeholder="Additional requests"
                  value={additional_requests}
                  onChange={event =>
                    this.setState({ additional_requests: event.target.value })
                  }
                  value={additional_requests}
                />
              </div>
            </div>
            <a id="request-a-quote-send-request" onClick={this.__send} className="button">
              Send request
            </a>
            {error ? (
              <p>Please fill all the inputs.</p>
            ) : sent ? (
              <p>Thank you! We will get back to you soon.</p>
            ) : (
              undefined
            )}
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
