import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import Navbar2Container from "./Navbar2Container";

class Screen extends React.PureComponent {
  render() {
    const {
      title = "Need quote in more detail?",
      description = "We support our worldwide principals with the best service for quickest and economical vessel turnarounds",
      news = false
    } = this.props;

    return (
      <div>
        <header className="header">
          <div className="container">
            <Navbar2Container />
            <div className="header__content">
              {news ? (
                <div className="row --default-bottom-margin">
                  <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1 news">
                    News
                  </div>
                </div>
              ) : (
                undefined
              )}
              <div className="row">
                <div
                  className={
                    news
                      ? "col-xs-12 col-sm-12 col-md-6 col-lg-5"
                      : "col-xs-12 col-sm-12 col-md-6 col-lg-4"
                  }
                >
                  <h1>{title}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-5">
                  <p className="--no-bottom-margin">{description}</p>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);

