import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import {
  FooterContainer,
  Header2Container,
  ServicesContactContainer,
} from "../Containers";

const MENUS = [
  "Provision From Eksay",
  "Bonded Stores And Beverages",
  "Technical Stores",
];

const Page = ({ page, title, children }) => {
  try {
    if (children[page]) {
      return (
        <div class="section__divider" id={"page-container-" + page}>
          <h2>{title}</h2>
          {children[page]}
        </div>
      );
    }
    return null;
  } catch (Error) {
    console.error(Error);
    return null;
  }
};

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: 0,
      title: undefined,
    };
  }

  render() {
    const { page = 0, title = MENUS[0] } = this.state;

    return (
      <div>
        <Header2Container title="Supply Services" description="" />
        <section>
          <div className="container">
            <div className="row section__divider ">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <div className="row section__divider">
                  <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                    <div className="solution__title">Eksay Supply Services</div>
                    <p>
                      We offer our services to all sorts of vessels, ranging from cargo vessels to bulk carriers or even marine equipment.
                    </p>
                    <p>
                      Our supply service is available all around the world and as the industry’s demands grow we adapt to every situation.
                    </p>
                    <div className="row section__divider">
                      <div className="col-xs-4 section__divider">
                      <div className="supplyscreen">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive2"><img
                            src="https://img.icons8.com/external-others-pike-picture/344/external-Refrigerator-cheese-others-pike-picture-2.png"/></div></div>
                          <div className="numbers__description">
                            Refrigerated Storage Area
                          </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-4 section__divider">
                      <div className="supplyscreen">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive2"><img
                            src="https://img.icons8.com/dotty/344/conference-background-selected.png"/></div></div>
                          <div className="numbers__description">
                            Staff
                          </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-4 section__divider">
                      <div className="supplyscreen">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive2"><img
                            src="https://img.icons8.com/dotty/344/link-company-parent.png"/></div></div>
                          <div className="numbers__description">
                            Head Office Area
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive1">
                      <img src="https://img.icons8.com/dotty/344/bottle-of-water.png" ></img>
                      </div></div>
                          <div className="numbers__description">Fresh Water</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive1">
                      <img src="https://img.icons8.com/external-bearicons-detailed-outline-bearicons/344/external-Port-location-bearicons-detailed-outline-bearicons.png" ></img>
                      </div></div>
                          <div className="numbers__description">
                            Port Of Service
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive1">
                      <img src="https://img.icons8.com/dotty/344/warehouse--v2.png" ></img>
                      </div></div>
                          <div className="numbers__description">Warehouse Area</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive1">
                      <img src="https://img.icons8.com/external-itim2101-lineal-itim2101/344/external-delivery-truck-online-shopping-itim2101-lineal-itim2101.png"></img>
                      </div></div>
                          <div className="numbers__description">Logistic Fleet</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive1">
                      <img src="https://img.icons8.com/dotty/344/buffet-breakfast.png"></img>
                      </div></div>
                          <div className="numbers__description">
                            General Provision
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 section__divider">
                    <div className="box">
                      <div className="box__content">
                        <div className="numbers">
                          <div className="numbers__title"><div className="responsive1">
                      <img src="https://img.icons8.com/external-wanicon-lineal-wanicon/344/external-construction-construction-wanicon-lineal-wanicon.png" ></img>
                      </div></div>
                          <div className="numbers__description">Technical Stores</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    
        <section>
          <div class="container">
            <div class="row section__divider">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div class="sidebar section__divider">
                  {MENUS.map((menu, m) => (
                    <a
                      class={page === m ? "item item--active" : "item"}
                      key={"menu-item-" + m}
                      id={"#menu-item-" + m}
                      onClick={() => this.setState({ page: m, title: menu })}
                      style={{ cursor: "pointer" }}
                    >
                      {menu}
                    </a>
                  ))}
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <Page page={page} title={title}>
                  <div id="page-0">
                  
                      <div className="responsive">
                      <img src="./assets/i/food.jpeg" ></img>
                      </div>
                      
                    <p>Our range of food and beverage supplies is almost infinite: From local products to a huge range of delicacies from all over the world. Through our global network of audited suppliers we ensure high quality standards.</p>
                    <p>Our food assortiment consists of fresh, frozen and dry provisions to serve the diverse international demands and their preferences. All our provisions are carefully selected for their quality and with respect for international food safety standards.</p>
                 <p>• Fresh food (Fruit & Vegetables)</p>
<p>• Frozen food</p>
<p>• Dry food</p>
<p>• Canned Food</p>
<p>• Dairies</p>
<p>• Asian and Specialties</p>
                  </div>
                  <div id="page-1">

                   <div className="responsive">
                      <img src="./assets/i/bonded.jpeg" ></img>
                      </div>
                    <p>
                      Provisions and canteen goods for the entire shipping industry, construction sites, research stations and military bases worldwide. Our standard program consists of dry and fresh provisions, frozen food and canned food, beverages + alcohol, tobacco, perfume, cosmetics, etc..
                    </p>
                  </div>
                  <div id="page-2">

<div className="responsive">
                      <img src="./assets/i/tech.jpeg" ></img>
                      </div>

                    <p>
                       We carries all types of ship accessories and nautical equipment for decks, engines and cabins. Charts, nautical publications, flags and  stationery as well as safety equipment, rust removers, brushes, tools and much more are available from our local  warehouses. </p>
                      <p> We  deliver engine stores, such as tools, bolts, screws, steel as well as heavy machinery and we supply electrical  equipment from a simple  light bulb to the most advanced electronics.
                    </p>
                    <p>Appliances </p>
                    <p>Chemicals </p>
                    <p>Lifeboat Accessories </p>
                    <p>Flares & Safety Equipment </p>
                    <p>Stationery </p>
                    <p>Computer & Electronics </p>
                    <p> Kitchen Supplies </p>
                    <p>Navigational Charts & Equipment </p>
                    <p>Rope, Cable, Shackles & Tools </p>

                  </div>
                </Page>

              </div>
      </div>
           
          </div>
          </section>
            <section>
            <div class="daraltici">
          <div class="container">
            <ServicesContactContainer />
          </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
