import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeaderContainer } from "../Containers";
import AirtablePlus from "airtable-plus";
import Markdown from "react-markdown";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      post: false
    };
  }

  async componentDidMount() {
    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Posts"
    });

    const post = await airtable.find(this.props.match.params.post);
    this.setState({ post: post, loading: false });
  }

  render() {
    const { loading, post } = this.state;

    return loading ? null : (
      <div>
        <HeaderContainer
          news={true}
          title={post.fields.Title}
          description={post.fields.Date}
        />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 section__divider">
                <div className="post">
                  <div
                    className="post__avatar"
                    style={{
                      height: 64,
                      width: 64,
                      borderRadius: 999,
                      overflow: "hidden"
                    }}
                  >
                    <img
                      src={
                        post.fields.Attachment.length > 0
                          ? post.fields.Attachment[0].url
                          : "/assets/i/post-avatar.svg"
                      }
                      alt=""
                      style={{ width: "100%", display: "block" }}
                    />
                  </div>
                  <div className="post__right">
                    <div className="post__right__title">
                      {post.fields["Author Name"]}
                    </div>
                    <div className="post__right__description">
                      {post.fields["Author Title"]}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-9">
                <Markdown source={post.fields.Content} />
              </div>
            </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
