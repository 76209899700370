import React from "react";
import { ScreenClassRender } from "react-grid-system";

const Component = ({ render }) => (
  <ScreenClassRender
    render={(screen) => {
      const mobile = ["xs", "sm"].includes(screen);

      return render ? render(mobile) : undefined;
    }}
  />
);

export default Component;
