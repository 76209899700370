import React from "react";
import { Navigator, Store } from "./Modules";
import { Provider } from "react-redux";

class App extends React.PureComponent {
  render() {
    return (
      <Provider store={Store}>
        <Navigator />
      </Provider>
    );
  }
}

export default App;
