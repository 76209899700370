import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeaderContainer } from "../Containers";

class Screen extends React.PureComponent {
  render() {
    return (
      <div>
        <HeaderContainer
          title="About Us"
          description="Your trusted partner for 38 years! We began our operations in 1983 with our head office based in Istanbul and the branch offices strategically located around the Turkish coast."
        />
        <section>
          <div className="container">
            <div className="row section__divider">
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <div className="sidebar section__divider">
                  <a className="item" href="#our-vision">
                    Our Vision
                  </a>
                  <a className="item" href="#our-mission">
                    Our Mission
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <p>
                  We began our operations in 1983 with our head office based in
                  Istanbul and the branch offices strategically located around
                  the Turkish coast. Since then, we have been providing agency
                  services at all Turkish Ports and Straits via knowledge of our
                  dedicated team of professionals, having distinct experience in
                  shipping agency services. Initially established as an agency
                  for chemical product tankers, we progressively expanded our
                  field of interest to meet the demands of our customers
                  worldwide. Now, we handle all types of tankers and dry bulk
                  carriers at all Turkish Ports and the Straits.
                </p>
                <div className="section__divider" id="our-vision">
                  <h2>Our Vision</h2>
                  <p>
                    For the sustainability and development of our success in all
                    Turkish Ports and Strait, we strive to create a difference
                    through always maintaining our high service quality at the
                    same level while keeping up with the international
                    standards. This is made possible through our corporate
                    culture, which supports the effective and fast moving
                    characteristics of our organisation. We develop continuously
                    as a team and enrich our experiences in such a way to create
                    a synergy in accordance with the requirements of our
                    customers. We foresee the variable requirements of the
                    marine sector and invest in the future of our company.
                  </p>
                </div>
                <div className="section__divider" id="our-mission">
                  <h2>Our Mission</h2>
                  <p>
                    We are committed to use our experience to collaborate with
                    our customers including a combination of ship owners,
                    charterers, shippers and receivers who all value our
                    professional service which enhances their own commercial
                    positions.
                  </p>
                </div>
                <div className="section__divider">
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <p>Among our goals are to;</p>
                    <br />
                    <p>• Provide safe, reliable, quality driven services</p>
                    <p>• Generate cost effective solutions for customers</p>
                    <p>• Always deliver on commitments</p>
                    <p>• Maintain/Ensure high quality standards</p>
                    <p>• Create added value for customers</p>
                    <p>• Achive customer satisfaction</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
