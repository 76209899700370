import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Mobile } from "../Components";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }
  render() {
    const { open = false } = this.state;

    return (
      <nav>
        <a className="logo2" href="/"></a>
        <Mobile
          render={(mobile) => (
            <>
              {mobile ? (
                <>
                  <img
                    onClick={() => this.setState({ open: true })}
                    src="/assets/i/icon-hamburger.svg"
                    style={{ opacity: 0.5, cursor: "pointer" }}
                  />
                  {open ? (
                    <div
                      style={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100vw",
                        height: "100%",
                        backgroundColor: "white",
                        padding: 16,
                        zIndex: 998,
                        boxSizing: "border-box"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                      >
                        <a
                          className="logo2"
                          href="/"
                          style={{ display: "block", marginBottom: 24 }}
                        ></a>
                        <img
                          onClick={() => this.setState({ open: false })}
                          src="/assets/i/icon-close.svg"
                          style={{ opacity: 0.5, cursor: "pointer" }}
                        />
                      </div>
                      <a
                        href="/about-us"
                        className="menu__item"
                        style={{
                          display: "block",
                          marginBottom: 24,
                          fontSize: 20,
                        }}
                      >
                        About Us
                      </a>
                      <a
                        href="/services"
                        className="menu__item"
                        style={{
                          display: "block",
                          marginBottom: 24,
                          fontSize: 20,
                        }}
                      >
                        Agency Services
                      </a>
                      <a
                        href="/supply"
                        className="menu__item"
                        style={{
                          display: "block",
                          marginBottom: 24,
                          fontSize: 20,
                        }}
                      >
                        Supply Services
                      </a>
                      <a
                        href="/team"
                        className="menu__item"
                        style={{
                          display: "block",
                          marginBottom: 24,
                          fontSize: 20,
                        }}
                      >
                        Team
                      </a>
                      <a
                        href="/contact"
                        className="menu__item"
                        style={{
                          display: "block",
                          marginBottom: 24,
                          fontSize: 20,
                        }}
                      >
                        Contact
                      </a>
                    </div>
                  ) : undefined}
                </>
              ) : (
                <>
                  <div className="menu">
                    <a href="/about-us" className="menu__item">
                      About Us
                    </a>
                    <a href="/services" className="menu__item">
                      Agency Services
                    </a>
                    <a href="/supply" className="menu__item">
                      Supply Services
                    </a>
                    <a href="/team" className="menu__item">
                      Team
                    </a>
                    <a href="/contact" className="menu__item">
                      Contact
                    </a>
                  </div>
                </>
              )}
            </>
          )}
        />
      </nav>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
