import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeaderContainer } from "../Containers";
import AirtablePlus from "airtable-plus";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      team: []
    };
  }

  async componentDidMount() {
    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Team"
    });

    const team = await airtable.read({
      sort: [{ field: "Order", direction: "asc" }]
    });
    this.setState({ team });
  }

  render() {
    const { team } = this.state;

    return (
      <div>
        <HeaderContainer
          title="Our Extaordinary Team"
          description="Our skilled, dynamic and responsive team is committed to make difference through highest service quality"
        />
        <section>
          <div className="container">
            <div className="row section__divider">
              {team.map((member, ___) => (
                <div
                  key={___}
                  className="col-xs-12 col-sm-12 col-md-6 col-lg-3 section__divider"
                >
                  <div className="team">
                    <div className="team__photo">
                      <img
                        src={
                          member.fields.Attachment.length > 0
                            ? member.fields.Attachment[0].url
                            : "/assets/i/team.svg"
                        }
                      />
                    </div>
                    <div className="team__title">{member.fields.Name}</div>
                    <div className="team__description">
                      {member.fields.Title}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
