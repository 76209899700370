import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeaderContainer } from "../Containers";
import AirtablePlus from "airtable-plus";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const Map = withScriptjs(
  withGoogleMap(props => (
    <div style={{ marginBottom: 24, height: 200 }}>
      <GoogleMap
        height={200}
        defaultZoom={12}
        defaultCenter={{ lat: props.lat, lng: props.lng }}
      >
        <Marker position={{ lat: props.lat, lng: props.lng }} />
      </GoogleMap>
    </div>
  ))
);

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      error: false,
      sent: false,
      offices: []
    };
  }

  async componentDidMount() {
    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Offices"
    });

    const offices = await airtable.read({
      sort: [{ field: "Order", direction: "asc" }]
    });
    this.setState({ offices });
  }

  __send = async () => {
    this.setState({ error: false });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.subject === "" ||
      this.state.message === ""
    ) {
      this.setState({ error: true });
      return;
    }

    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Offices"
    });

    const create = await airtable.create(
      {
        Name: this.state.name,
        Email: this.state.email,
        Subject: this.state.subject,
        Message: this.state.message
      },
      { tableName: "Website Content - Contacts" }
    );

    this.setState({
      sent: true,
      name: "",
      email: "",
      subject: "",
      message: ""
    });
  };

  render() {
    const { name, email, subject, message, error, sent, offices } = this.state;
    return (
      <div>
        <HeaderContainer
          title="Need quote in more detail?"
          description="We support our worldwide principals with the best service for quickest and economical vessel turnarounds"
        />
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
                <h2>Contact</h2>
                <div className="row section__divider">
                  <div className="icon-container">
                    <div className="icon-container__icon">
                      <img src="/assets/i/icon-user.svg" alt="" />
                    </div>
                    <input
                      className="input input--icon"
                      placeholder="Name Surname"
                      name="name"
                      value={name}
                      onChange={event =>
                        this.setState({ name: event.target.value })
                      }
                    />
                  </div>
                  <div className="icon-container">
                    <div className="icon-container__icon">
                      <img src="/assets/i/icon-email.svg" alt="" />
                    </div>
                    <input
                      className="input input--icon"
                      placeholder="Your E-Mail"
                      type="email"
                      name="email"
                      value={email}
                      onChange={event =>
                        this.setState({ email: event.target.value })
                      }
                    />
                  </div>
                  <input
                    className="input"
                    placeholder="Subject"
                    value={subject}
                    onChange={event =>
                      this.setState({ subject: event.target.value })
                    }
                  />
                  <textarea
                    className="yourmessage"
                    placeholder="Additional requests"
                    value={message}
                    onChange={event =>
                      this.setState({ message: event.target.value })
                    }
                  />
                  <a id="contact-send-message" className="button" onClick={this.__send}>
                    Send message
                  </a>
                  {error ? (
                    <p>Please fill all the inputs.</p>
                  ) : sent ? (
                    <p>Thank you! We will get back to you soon.</p>
                  ) : (
                    undefined
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                <h2>Our Services</h2>
                <div className="row">
                  {offices.map((office, ___) => (
                    <div
                      key={___}
                      className="col-xs-12 col-sm-12 col-md-4 col-lg-4"
                    >
                      <div className="map">
                        <Map
                          lat={office.fields.Latitude}
                          lng={office.fields.Longitude}
                          loadingElement={
                            <div
                              style={{ height: `200px`, marginBottom: 24 }}
                            />
                          }
                          containerElement={
                            <div
                              style={{ height: `200px`, marginBottom: 24 }}
                            />
                          }
                          mapElement={<div style={{ height: `100%` }} />}
                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCUpHRbK2_yb0uHxkN-z60muE6ez6aSCnc&callback=initMap"
                        />
                        <div className="map__title">{office.fields.Name}</div>
                        <div className="map__description">
                          {office.fields.Description}
                        </div>
                        <div className="map__address">
                          {office.fields.Details}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
