import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  HomeScreen,
  AboutScreen,
  TeamScreen,
  PostScreen,
  ServicesScreen,
  SupplyScreen,
  ContactScreen,
  QuoteScreen,
  NewsScreen,
  ExampleScreen
} from "../Screens";

class Component extends React.PureComponent {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/about-us" component={AboutScreen} />
          <Route exact path="/team" component={TeamScreen} />
          <Route exact path="/news/:post" component={PostScreen} />
          <Route exact path="/services" component={ServicesScreen} />
          <Route exact path="/supply" component={SupplyScreen} />
          <Route exact path="/contact" component={ContactScreen} />
          <Route exact path="/request-a-quote" component={QuoteScreen} />
          <Route exact path="/news" component={NewsScreen} />
          <Route exact path="/example" component={ExampleScreen} />
        </Switch>
      </Router>
    );
  }
}

export default Component;
