import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import NavbarContainer from "./NavbarContainer";
import AirtablePlus from "airtable-plus";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      sent: false,
    };
  }

  __send = async () => {
    this.setState({ error: false });

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.phone === ""
    ) {
      this.setState({ sent: false });
      return;
    }

    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Request a Quote",
    });

    const create = await airtable.create(
      {
        Name: this.state.name,
        Email: this.state.email,
        Phone: this.state.phone,
      },
      { tableName: "Website Content - Supply Services" }
    );

    this.setState({
      name: "",
      email: "",
      phone: "",
      sent: true,
    });
  };

  render() {
    const { name, email, phone, sent } = this.state;

    return (
      <div className="quote">
        <div className="quote__header">
          <h3>Need quote in more detail</h3>
          <p>info@eksay.com — +90 216 302 01 29</p>
        </div>
        <div className="quote__footer">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <div className="icon-container">
                <div className="icon-container__icon">
                  <img src="/assets/i/icon-user.svg" />
                </div>
                <input
                  className="input input--icon"
                  placeholder="Name Surname"
                  value={name}
                  onChange={(event) =>
                    this.setState({ name: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <div className="icon-container">
                <div className="icon-container__icon">
                  <img src="/assets/i/icon-email.svg" />
                </div>
                <input
                  className="input input--icon"
                  placeholder="E-Mail"
                  value={email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <div className="icon-container">
                <div className="icon-container__icon">
                  <img src="/assets/i/icon-phone.svg" />
                </div>
                <input
                  className="input input--icon"
                  placeholder="Phone"
                  value={phone}
                  onChange={(event) =>
                    this.setState({ phone: event.target.value })
                  }
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
              <a
                id="home-get-started"
                className="button"
                onClick={this.__send}
              >
                {sent ? "Sent!" : "Contact"}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value,
      }),
  };
}

function map(state) {
  return {
    redux: state,
  };
}

export default connect(map, dispatcher)(Screen);
