import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { FooterContainer, HeaderContainer } from "../Containers";
import AirtablePlus from "airtable-plus";

class Screen extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      news: []
    };
  }

  async componentDidMount() {
    const airtable = new AirtablePlus({
      baseID: "apppaHtajXsoBHsqe",
      apiKey: "keyUP5hmhj7dQDdN3",
      tableName: "Website Content - Posts"
    });

    const news = await airtable.read({ news: "Website" });
    this.setState({ news });
  }

  render() {
    const { news } = this.state;
    return (
      <div>
        <HeaderContainer
          title="Latest news"
          description="Check out our latest news. Make sure to come frequently for world maritime news and our company updates."
        />
        <section>
          <div class="container">
            <div class="row">
              {news.map((post, ___) => (
                <a
                  key={___}
                  href={
                    post.fields.Outside ? post.fields.URL : "/news/" + post.id
                  }
                  class="col-xs-12 col-sm-6 col-md-6 col-lg-3"
                >
                  <div class="announce">
                    <div class="announce__header">news</div>
                    <div class="announce__title">{post.fields.Title}</div>
                    <div class="announce__description">
                      <p>
                        {post.fields.Description.length > 200
                          ? post.fields.Description.substring(0, 200) + "..."
                          : post.fields.Description.length}
                      </p>
                    </div>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>
        <FooterContainer />
      </div>
    );
  }
}

function dispatcher(dispatch) {
  return {
    core: (key, value) =>
      dispatch({
        type: "CORE",
        key: key,
        value: value
      })
  };
}

function map(state) {
  return {
    redux: state
  };
}

export default connect(map, dispatcher)(Screen);
